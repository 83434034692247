import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormlyService {
  action: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  action$: Observable<string | null> = this.action.asObservable();

  constructor() {}
  setAction(action: string | null) {
    this.action.next(action);
  }

  navigateToSubform: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  navigateToSubform$: Observable<string | null> = this.navigateToSubform.asObservable();
  setNavigateToSubform(path: string | null) {
    this.navigateToSubform.next(path);
  }

  comment: BehaviorSubject<{ parent: string; key: string; comment: string } | null> = new BehaviorSubject<{
    parent: string;
    key: string;
    comment: string;
  } | null>(null);

  comment$: Observable<{ parent: string; key: string; comment: string } | null> = this.comment.asObservable();
  setComment(commentData: { parent: string; key: string; comment: string } | null) {
    this.comment.next(commentData);
  }
}
